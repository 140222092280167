import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file for the Navbar

function Navbar() {
  const token = localStorage.getItem('token');
  const hospital = localStorage.getItem('hospital');
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('hospital');
    navigate('/');
  };

  return (
    <nav className="navbar">
      {/* Left side of the navbar */}
      <div>
        <Link to="/" className="navbar-link">{token ? "NICU Rounds (Home)" : "NICU Rounds"}</Link>

      </div>
      
      {/* Right side of the navbar */}
      <div className="navbar-right">
        <ul className="navbar-list">
          {token ? (
            <>
            <span className="navbar-hospital">
                Link to your guideline: <a href={`https://nicurounds.com/${hospital}`} target="_blank" rel="noopener noreferrer">
                  https://nicurounds.com/{hospital}
                </a>
              </span>
              <li className="navbar-item">
                <Link to="/admin" className="navbar-link">Admin Page</Link>
              </li>
              <li className="navbar-item">
                <button onClick={handleLogout} className="navbar-button">Logout</button>
              </li>
            </>
          ) : (
            <>
              <li className="navbar-item">
                <Link to="/signup" className="navbar-link">Sign Up</Link>
              </li>
              <li className="navbar-item">
                <Link to="/login" className="navbar-link">Login</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
