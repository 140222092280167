import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ResponsiveWarning = () => {
  const [isTooSmall, setIsTooSmall] = useState(window.innerWidth < 768);
  const [isDismissed, setIsDismissed] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const handleResize = () => {
      setIsTooSmall(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isTooSmall || isDismissed) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh", // Full screen
        backgroundColor: "rgba(255, 0, 0, 0.9)",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px",
        zIndex: 2000, // Increased z-index to appear above other elements
      }}
    >
      <h2>⚠️ The Admin Page is not yet configured for mobile</h2>
      <p>Please use a larger screen for the best experience.</p>
      <Link
        to="/"
        className="navbar-link"
        style={{
          color: "white",
          fontSize: "18px",
          textDecoration: "underline",
          margin: "10px 0",
        }}
      >
        Go To Home Page
      </Link>
      <button
        onClick={() => setIsDismissed(true)}
        style={{
          marginTop: "15px",
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "black",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Dismiss
      </button>
    </div>
  );
};

export default ResponsiveWarning;
