import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import dataParser from './DataParser';
import DOMPurify from 'dompurify';
import ExpandablePanel from '../components/ExpandablePanel';
import './MainPage.css'
import CopyButton from '../components/CopyButton';
import ToggleSwitch from '../components/ToggleSwitch';

function HospitalPage() {
  const { hospitalName } = useParams();
  const [guidelines, setGuidelines] = useState([]);
  const [error, setError] = useState('');
  const [selectedHospital, setSelectedHospital] = useState(localStorage.getItem('hospital') || 'CookChildrens');
  const [inputGestAgeWeeks, setInputGestAgeWeeks] = useState('');
  const [inputGestAgeDays, setInputGestAgeDays] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [birthWeight, setBirthWeight] = useState('');
  const [output, setOutput] = useState([]);
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [sortBySystems, setSortBySystems] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const gestAgeDays =
    (inputGestAgeWeeks ? parseInt(inputGestAgeWeeks, 10) * 7 : 0) +
    (inputGestAgeDays ? parseInt(inputGestAgeDays, 10) : 0);

  useEffect(() => {
    const fetchGuidelines = async () => {
        try {
          const res = await axios.get(`https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/guideline/${hospitalName}`);
          setGuidelines(res.data);
          setError('');
        } catch (err) {
          console.error('Failed to fetch guidelines:', err.response?.data?.message || err.message);
          setError("Hospital not found in the database. Please check that the hospital name is typed correctly in the URL and that the URL starts with 'https://'.");
        }
      };

    fetchGuidelines();
  }, [hospitalName]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const treatmentList = dataParser(guidelines, birthWeight, gestAgeDays, dateOfBirth, sortBySystems);

console.log(treatmentList)

const getDateClass = (date) => {
  if (!date || isNaN(Date.parse(date))) {
    return 'invalid-date';
  }

  // Convert both dates to the same locale-specific date string format
  const currentDateStr = new Date().toLocaleDateString('en-US', );
  const comparisonDateStr = date.toLocaleDateString('en-US', );

  console.log("Current Date:", currentDateStr, "Comparison Date:", comparisonDateStr);

  if (currentDateStr === comparisonDateStr) {
    return 'current-date';
  } else if (new Date(comparisonDateStr) < new Date(currentDateStr)) {
    return 'past-date';
  } else {
    return 'future-date';
  }
};

const getOutputText = () => {
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  return output.map(item => {
    const text = item.description;
    return stripHtmlTags(text);
  }).join('\n');
};

const handleSubmit = (e) => {
  e.preventDefault();
  setShowCopyButton(true);
  setOutput(treatmentList);
};

const handleToggleSortBySystems = () => {
  setSortBySystems(!sortBySystems);
};

  return (
    <div>
    <div className="NeoTool">
      <form onSubmit={handleSubmit}>
        <h1>NICU Rounds</h1>
        <h4>Treatment Plan Generator for NICU Patients</h4>
        <h2>Practice Code: {hospitalName}</h2>
        {errorMessage && (
          <div className="error-message-guidelines">
            <p>{errorMessage}</p>
            <ul>
              <li>Check your internet connection.</li>
              <li>
                Make sure you are at the https url: 
                <a href={`https://nicurounds.com/${hospitalName}`} target="_blank" rel="noopener noreferrer">
                  https://nicurounds.com/{hospitalName}
                </a>
              </li>
              <li>Switch to a browser that will automatically redirct you https (Chrome or Brave).</li>
              <li>Try again later, the site may be undergoing maintenance.</li>
            </ul>
          </div>
        )}
        
        <div className="input-group">
          <label htmlFor="gestational-age-weeks">Birth Gestational Age:</label>
          <input
            className="weeks-input"
            id="gestational-age-weeks"
            type="text"
            value={inputGestAgeWeeks}
            onChange={(e) => setInputGestAgeWeeks(e.target.value)}
            placeholder="Weeks"
          />
          <label className="label-inline" htmlFor="gestational-age-days">w</label>
          <input
            className="days-input"
            id="gestational-age-days"
            type="text"
            value={inputGestAgeDays}
            onChange={(e) => setInputGestAgeDays(e.target.value)}
            placeholder="Days"
          />
          <label className="label-inline">d</label>
        </div>

        <div className="input-group">
          <label htmlFor="dob">DOB:</label>
          <input
            id="dob"
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="birth-weight">Birth weight:</label>
          <input
            className="weight-input"
            id="birth-weight"
            type="text"
            value={birthWeight}
            onChange={(e) => setBirthWeight(e.target.value)}
            placeholder="Grams"
          />
          <label className="label-inline">g</label>
        </div>

        <div className="button-container">
          <button type="submit" className="generate-button">
            Generate Treatment Plan
          </button>
          <CopyButton getText={getOutputText} show={showCopyButton} />
          <button onClick={handleToggleSortBySystems} className="toggle-sort-button">
      {sortBySystems ? 'Sort Mode: Systems' : 'Sort Mode: Date'}
    </button>
        </div>
      </form>

      <div className="output-section">
        {output.map((item, index) => {
          const dateClass = getDateClass(item.date || '');
          const sanitizedDescription = DOMPurify.sanitize(item.description || '');

          return (
            <div key={index} className={`${dateClass} item-container`}>
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                className="item-description"
              />
              {item.source && (
                <a href={item.source} target="_blank" rel="noopener noreferrer" className="item-source">
                  ⓘ
                </a>
              )}
            </div>
          );
        })}
      </div>

      {/* Disclaimer Section */}
      <div className="disclaimer">
        <h3>Disclaimer</h3>
        <p>
          By using this website, you acknowledge the possibility that there may be incomplete or inaccurate information presented. The creator is not responsible for any harm or adverse outcomes resulting from the use of the information provided. This website is intended for reference purposes only and should not replace professional medical judgment or guidance.
        </p>
      </div>

      {/* Footer Section */}
      <footer className="NeoTool-footer">
        <p>Made by Cameron Jobson</p>
        <p>
          Contact info: <br />
          Email: <a href="mailto:cameronajobson@gmail.com">cameronajobson@gmail.com</a> <br />
          Phone: <a href="tel:+18173198996">817-319-8996</a>
        </p>
      </footer>
    </div>
  </div>
);
}

export default HospitalPage;