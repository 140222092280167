import React, { useState, useEffect } from "react";
import axios from "axios";

function ToggleVisibilityButton({ hospital, token }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(null); // true = public, false = private
  const [error, setError] = useState(null);

  // Fetch the current status when the component mounts
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const res = await axios.get(
          `https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/guideline/guideline-status/${hospital}`
        );
        // The API returns { isPrivate: <boolean> }.
        // If isPrivate is true, then isPublic should be false.
        setIsPublic(!res.data.isPrivate);
      } catch (err) {
        console.error(
          "Error fetching status:",
          err.response?.data?.message || err.message
        );
        setError(err.response?.data?.message || "Error fetching status");
      }
    };
    fetchStatus();
  }, [hospital]);

  // Toggle the visibility status by calling the API
  const toggleVisibility = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.put(
        `https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/guideline/toggle-visibility/${hospital}`,
        {}, // No request body needed
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      console.log("Visibility toggled:", response.data);
      // The PUT endpoint returns { isPublic: <boolean> } directly.
      setIsPublic(response.data.isPublic);
    } catch (err) {
      console.error(
        "Error toggling visibility:",
        err.response?.data?.message || err.message
      );
      setError(err.response?.data?.message || "Error toggling visibility");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
              {error && <p className="error">{error}</p>}
      {isPublic !== null && (
        <p>Your guideline link is currently {isPublic ? "enabled" : "disabled"}.</p>
      )}
      <button onClick={toggleVisibility} disabled={isLoading}>
        {isLoading ? "Toggling..." : "Toggle Visibility"}
      </button>
    </div>
  );
}

export default ToggleVisibilityButton;
