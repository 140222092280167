import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SignupPage.css';

function SignupPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hospital, setHospital] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/auth/register', {
        username,
        password,
        hospital,
      });

      console.log(res.data.message); // Should display "Admin registered successfully"
        // Set the success message
        setSuccessMessage("Admin registered successfully! Please log in.");
        setErrorMessage(''); // Clear any error messages
    } catch (err) {
      setErrorMessage(err.response?.data?.message || err.message);
      setSuccessMessage(''); // Clear any previous success message
      console.error('Signup failed:', err.response?.data?.message || err.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="signup-container">
      
      <form className="signup-form" onSubmit={handleSignup}>
      <h1 className="login-title">Sign Up</h1>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        <input
          type="text"
          value={hospital}
          onChange={(e) => setHospital(e.target.value)}
          placeholder="Hospital"
          required
        />
        <div className="password-container">
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <div className="checkbox-wrapper">
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="show-password"
                checked={showPassword}
                onChange={togglePasswordVisibility}
              />
              <label htmlFor="show-password">Show Password</label>
            </div>
          </div>
        </div>
        <button type="submit">Create Account</button>
        <div>
            {/* Display success message */}
            {successMessage && <p className="form-success-message">{successMessage}</p>}

            {/* Display error message */}
            {errorMessage && <p className="form-error-message">{errorMessage}</p>}
        </div>
      </form>
    </div>
  );
}

export default SignupPage;
